import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import Service from './Service/Service'
import {getServices, getServicesOther, getCurrency} from 'store/app/selectors'
import { IService } from 'interfaces/IService'
import './services.scss'

import { useTranslation } from 'react-i18next';
import Title from "../../Template/Title/Title";

const ruServices = [
	{
		name: 'Покупка игровых бонусов DP',
		cost: '1 DP = 1 ₽',
		auth: false,
	},
	{
		name: 'VIP статус на 30 дней',
		cost: '399 ₽',
		auth: false,
	},
	{
		name: 'Gold VIP статус на 30 дней',
		cost: '999 ₽',
		auth: false,
	},
	{
		name: 'Военный билет для персонажа',
		cost: '1000 ₽',
		auth: false,
	},
	{
		name: 'Снятие варна с персонажа',
		cost: '300 ₽',
		auth: false,
	},
	{
		name: 'Смена имени персонажа',
		cost: '30 ₽',
		auth: false,
	},
	{
		name: 'Смена внешности персонажа',
		cost: '150 ₽',
		auth: false,
	},
	{
		name: 'Смена возраста персонажа',
		cost: '10 ₽',
		auth: false,
	},
	{
		name: 'Убрать наркозависимость',
		cost: '100 ₽',
		auth: true,
	},
]

const enServices = [
	{
		name: 'Purchase of game bonuses DP',
		cost: '1 DP = 1 ₽',
		auth: false,
	},
	{
		name: 'VIP status for 30 days',
		cost: '399 ₽',
		auth: false,
	},
	{
		name: 'Gold VIP status for 30 days',
		cost: '999 ₽',
		auth: false,
	},
	{
		name: 'Military ticket for character',
		cost: '1000 ₽',
		auth: false,
	},
	{
		name: 'Remove warning from character',
		cost: '300 ₽',
		auth: false,
	},
	{
		name: 'Change character name',
		cost: '30 ₽',
		auth: false,
	},
	{
		name: 'Change character appearance',
		cost: '150 ₽',
		auth: false,
	},
	{
		name: 'Change character age',
		cost: '10 ₽',
		auth: false,
	},
	{
		name: 'Remove drug addiction',
		cost: '100 ₽',
		auth: true,
	},
]

const Services: FC = () => {
	const currency = useSelector(getCurrency)
	const services = useSelector(getServices)
	const servicesOther = useSelector(getServicesOther)


	const { t, i18n } = useTranslation();

	const r = i18n.language === 'ru' ? services : servicesOther

	return (
		<div className="donate-services">
			<Title bgTitle={t('list_of_services').toUpperCase()} className="donate__title">
				{t('list_of_services')}
			</Title>
			{ r.map(({ name, cost, auth }: IService) => (
				<Service
					key={name}
					name={name}
					cost={cost}
					auth={auth}
				/>
			))}
		</div>
	)
}

export default Services
