import React, { FC } from 'react'
import Title from 'components/Template/Title/Title'

import { useTranslation } from 'react-i18next';

const PaymentSuccess: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="payment-status">
			<Title
				gradient={true}
				bg={true}
				bgTitle={t('payment_success')}
				centered={true}
				className="payment-status__title"
			>
				{t('payment_success_title')}
			</Title>
			<p className="payment-status__text">
				{t('payment_success_text')}
			</p>
		</div>
	)
}

export default PaymentSuccess
