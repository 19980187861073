import initialState from "./state"
import { CaptchaActionTypes } from './types/actions'
import {
	SET_CAPTCHA_ACTION,
	SET_CAPTCHA_KEY,
	SET_CAPTCHA_TOKEN
} from './types'

export const captchaReducer = (state = initialState, { type, payload }: CaptchaActionTypes) => {
	switch (type) {
		case SET_CAPTCHA_KEY: {
			return {
				...state,
				captchaKey: payload
			}
		}
		case SET_CAPTCHA_ACTION: {
			return {
				...state,
				captchaAction: payload
			}
		}
		case SET_CAPTCHA_TOKEN: {
			return {
				...state,
				captchaToken: payload
			}
		}
		default: return state
	}
}
