import React, { FC } from 'react'
import { useTokenControl } from 'hooks/useTokenControl'
import { useMeta} from "./hooks/useMeta";
import Template from 'components/Template/Template'
import { Controller as RouterController } from 'components/Router/Controller/Controller'
import Captcha from 'components/Captcha/Captcha'
import Feedback from './components/Feedback/Feedback'
import { useSelector } from "react-redux";
import { getMetaFetchingStatus, getLocale } from "./store/app/selectors";
import { useTranslation } from 'react-i18next'
import Preloader from 'components/Entities/Preloader/Preloader'

const App: FC = () => {
  useTokenControl()

  return (
    <div className="page-container">
      <Template>
        <RouterController />
        <Captcha />
        <Feedback />
      </Template>
    </div>
  )
}

const Setup = () => {
  useMeta()

  const { i18n } = useTranslation()

  const metaStatus = useSelector(getMetaFetchingStatus)
  const locale = useSelector(getLocale)

  React.useEffect(() => {
    i18n.changeLanguage(locale)
  }, [i18n, locale])

  if (metaStatus === 'pending') {
    return (
      <div className="app-preloader">
        <div className="app-preloader__el">
          <Preloader />
        </div>
      </div>
    )
  }

  return <App />
}

export default Setup
