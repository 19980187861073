import React, { FC } from 'react'
import { Fail as FailedState } from 'components/Entities/Gateway/Fail/Fail'

import { useTranslation } from 'react-i18next';

const Fail: FC = () => {
	const { t } = useTranslation();

	return (
		<FailedState message={t('servers_unavailable')} />
	)
}

export default Fail
