import React, { FC } from 'react'
import './fail.scss'

interface FailProps {
	message: string,
}

const Fail: FC<FailProps> = ({ message }) => {
	return (
		<div className="state-fail">
			<div className="state-fail__container">
				<p className="state-fail__message">
					{ message }
				</p>
			</div>
		</div>
	)
}

export {
	Fail
}
