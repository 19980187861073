import React, {FC, Fragment, HTMLAttributes, useMemo} from 'react'
import { Server as ServerEntity } from 'components/Entities/Server/Server'
import './server.scss'

import { useTranslation } from 'react-i18next';

interface IContainerProps {
	players: number,
	status: boolean,
	max_players: number;
}

const Container: FC<IContainerProps> = (
	{ players,
		status,
		max_players
	}) => {

	const { t } = useTranslation();

	const classNames = useMemo(() => {
		return`home-server__online
			${status
				?'home-server__online_engaged'
				: 'home-server__online_disengaged'
			}`
	}, [status])

	const playersPhrase = useMemo(() => {
		if (!status) {
			return 0
		}
		if (players > max_players) {
			return max_players
		}
		return `${t('online')} ${players}`
	}, [players, status])

	return (
		<div className={classNames}>
			<span>
				{ status &&
					<Fragment>
						{ players > max_players &&
							<Fragment>
								{t('online')} { playersPhrase }
                <p>({ players - max_players } {t('in_queue')})</p>
							</Fragment>
						}
						{ players <= max_players && playersPhrase }
					</Fragment>
				}
				{ !status && t('server_offline')}
			</span>
		</div>
	)
}

interface IFooterProps {
	players: number,
	status: boolean,
	max_players: number;
}

const Footer: FC<IFooterProps> = ({ players, status,max_players }) => {

	const { t } = useTranslation();

	const playersPercent = useMemo(() => {
		if (!status) return 0
		if (players >= max_players) return 100
		return players / max_players * 100
	}, [players])

	const bgStyles = useMemo(() => {
		return {
			width: `${status ? playersPercent : 0}%`
		}
	}, [playersPercent])

	return (
		<div className="online-bar">
			<span className="online-bar__value">
				{ status ? `${t('online')} ${ players >= max_players ? max_players : players } / ${max_players}` : 'OFFLINE'}
			</span>
			<div className="online-bar__bg" style={bgStyles} />
		</div>
	)
}

interface IServerProps extends HTMLAttributes<HTMLDivElement> {
	serverId: number,
	serverName: string,
	background: string,
	players: number,
	status: boolean,
	max_players: number;
}

const Server: FC<IServerProps> = (
	{ serverId,
		serverName,
		background,
		players,
		status,
		max_players,
		...restProps
	}) => {

	return (
		<div className="home-server">
			<ServerEntity
				serverId={serverId}
				serverName={serverName}
				background={background}
				toContainer={<Container players={players} status={status} max_players={max_players} />}
				toFooter={<Footer players={players} status={status} max_players={max_players} />}
				{ ...restProps }
			/>
		</div>
	)
}

export default Server
