import React, {
  FC,
  memo,
  useCallback,
  useMemo,
  Fragment,
} from 'react'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

  import Button from '../../Entities/Form/Button/Button'
import PaymentMethod from './PaymentMethod/PaymentMethod'
import { IPaymentMethod } from 'interfaces/IPaymentMethod'
import { IPaymentMethods } from 'interfaces/IPaymentMethods'
import './paymentMethods.scss'
import {useSelector} from 'react-redux'
import {getUserStatus} from '../../../store/user/selectors'
import {getFund} from '../../../store/app/selectors'

import { useTranslation } from 'react-i18next';

interface IPaymentMethodsProps extends IPaymentMethods {
	sum?: number,
	handlePaymentClick: Function,
  togglePromo: () => void,
	email?: string;
}

const PaymentMethods: FC<IPaymentMethodsProps> = (
	{ paymentMethods,
		sum = 0,
		handlePaymentClick,
    togglePromo,
		email,
	}) => {

	const simpleBarStyles = useMemo(() => {
		return {
			maxHeight: 390
		}
	}, [])

  const handleToggleClick = () => {
    togglePromo()
  }

  const authenticated = useSelector(getUserStatus)
  const fund = useSelector(getFund)

	const { t } = useTranslation();

	return (
		<div className="donate-payments">
      <div className="donate-payments__header">
        <h2 className="donate-payments__title">
			{t('choose')}<br />
			{t('payment_method')}
        </h2>

        { ( authenticated || fund?.entity?.email || email ) && (
          <Fragment>
            <p className="donate-payments__or">
				{t('or')}
            </p>

            <div className="donate-payments__action">

              <Button
                className="donate-payments__activate"
                onClick={() => handleToggleClick()}
              >
				  {t('activate_key')}
              </Button>
            </div>
          </Fragment>
        ) }
      </div>
			<div className="donate-payment__root layout__correction">
				<SimpleBar
					autoHide={false}
					style={simpleBarStyles}
				>
					<div className="donate-payments__container">
            { paymentMethods.length >= 1 && paymentMethods.map((group) => (
              <div
                key={group.name}
                className="donate-payments__group"
              >
                <h2 className="donate-payments__subtitle">
                  {  group.name }
                </h2>
                <div className="donate-payments__subcontainer">
                  {/*@ts-ignore*/}
                  { group.items.length >= 1 && group.items.map(({
                    id,
                    ico,
                    min,
                    name,
                    donateKeys,
                  }: IPaymentMethod) => (
                  	<PaymentMethod
                  		key={id}
                  		id={id}
                  		ico={ico}
                  		min={min}
                  		name={name}
                  		sum={sum}
                  		handleClick={handlePaymentClick}
                      	donateKeys={donateKeys}
                  	/>
                  ))}
                </div>
              </div>
            )) }
					</div>
				</SimpleBar>
			</div>
		</div>
	)
}

export default memo(PaymentMethods)
