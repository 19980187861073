import React, { FC } from 'react'
import Title from 'components/Template/Title/Title'
import { getGtaV, discord } from 'router/links'
import {HashLink, HashLink as Link} from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

const Play: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="play" id="play">
			<Title
				gradient={true}
				bg={true}
				bgTitle={t('how_to_title')}
				className="play__title"
			>
				{t('how_to_title')} {t('begin_to_play')}
			</Title>
			<div className="play-steps">
				{ /* Первый шаг */ }
				<div className="play-step play-step_blue">
					<div className="play-step__container">
						<div className="play-step__pic">
							<img
								alt="gta5rp game"
								src="/assets/images/gtav.svg"
							/>
						</div>
						<div className="play-step__about">
							<p>
								{t('buy_install')} <br />
								{t('licensed_game')} GTA V
							</p>
						</div>
						<div className="play-step__bg" />
					</div>
					<a href={getGtaV} target="_blank">
						<button className="play-step__btn">
							{t('steam_buy')}
						</button>
					</a>
				</div>
				{ /* Второй шаг */ }
				<div className="play-step play-step_sea">
					<div className="play-step__container">
						<div className="play-step__pic">
							<img
								src="/assets/images/monitor.png"
								alt="monitor gta5rp"
							/>
						</div>
						<div className="play-step__about">
							<p>
								{t('join_official')}<br />
								{t('to_discord_server')}
							</p>
						</div>
						<div className="play-step__bg" />
					</div>
					<a href={discord} target="_blank">
						<button className="play-step__btn">
							{t('discord_server')}
						</button>
					</a>
				</div>
				{ /* Третий шаг */ }
				<div className="play-step play-step_orange">
					<div className="play-step__container">
						<div className="play-step__pic">
							<img
								src="/assets/images/video.png"
								alt="monitor gta5rp"
							/>
						</div>
						<div className="play-step__about">
							<p>
								{t('step_about_1')} <br />
								{t('step_about_2')} <br />
								{t('step_about_3')}
							</p>
						</div>
						<div className="play-step__bg" />
					</div>
					<HashLink to="/#servers" smooth={true}>
						<button className="play-step__btn">
							{t('play_online')}
						</button>
					</HashLink>
				</div>
			</div>
		</div>
	)
}

export default Play
