import React, { FC, useEffect } from 'react'
import { Route as EntityRoute } from 'react-router-dom'
import { IRouteInterface } from 'interfaces/IRouteInterface'

interface IRouteProps extends IRouteInterface {}

const Route: FC<IRouteProps> = (
	{ title = 'GTA5RP Game',
		...restProps
	}) => {

	useEffect(() => {
		document.title = title
	}, [])

	return <EntityRoute { ...restProps } />
}

export {
	Route
}
