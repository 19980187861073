import React, { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PendingProfile from 'components/PendingProfile/PendingProfile'
import { setUserToken } from 'store/user/actions'
import { getUserStatus } from 'store/user/selectors'

interface IToServersProps {
	token: string,
	serverId: string,
}

const ToServer: FC = () => {
	const { token, serverId } = useParams<IToServersProps>()
	const dispatch = useDispatch()
	const history = useHistory()
	const isAuthenticated = useSelector(getUserStatus)

	useEffect(() => {
		dispatch(setUserToken(token))
	}, [])

	useEffect(() => {
		if (isAuthenticated === true) {
			history.push(`/donate/${serverId}`)
		}
	}, [isAuthenticated])

	return (
		<PendingProfile />
	)
}

export default ToServer
