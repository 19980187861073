import React, { FC, useMemo, useState } from 'react'
//@ts-ignore
import Tooltip from "react-simple-tooltip"

import { IPaymentMethod } from 'interfaces/IPaymentMethod'
import './paymentMethod.scss'
import {useSelector} from "react-redux";
import {getCurrency} from "../../../../store/app/selectors";

import { useTranslation } from 'react-i18next';

interface IPaymentMethodProps extends IPaymentMethod {
	sum?: number,
	handleClick: Function
}

const PaymentMethod: FC<IPaymentMethodProps> = (
	{ id,
		ico,
		min,
		sum = 0,
		handleClick,
    donateKeys,
	}) => {

	const { t } = useTranslation();

	const currency = useSelector(getCurrency)

	const disabled = sum < min
	
	const withTooltip = id === 'mc_uah' || id === 'visa_world'

	const paymentMethodClasses = useMemo(() => {
		return `payment-method ${sum < min && 'payment-method_disabled'}`
	}, [min])
	
	const Component = (
		<div
			className={paymentMethodClasses}
			onClick={() => handleClick(id, donateKeys)}
		>
			{ ico && <img src={ico} alt={id} className="payment-method__picture" /> }
			{ (disabled && !withTooltip) && <span className="payment-method__warning">{t('at_least')} {min} {currency}.</span> }
			<div className="payment-method__bg" />
			{ donateKeys && <div className="payment-method__key" /> }
		</div>
	)

	const tooltipContent = useMemo(() => {
		if (disabled) {
			return t('min_donation_amount') + ' ' + min + ' ' + currency
		}

		if (id === 'mc_uah') {
			return t('only_mastercard') + '.'
		}

		if (id === 'visa_world') {
			return t('only_visa') + '.'
		}

		return t('payment_failed_alert')
	}, [min, id, currency])
	
	if (!withTooltip) return Component

	return (
		<Tooltip
			content={tooltipContent}
			zIndex={15}
			border={'rgba(77, 98, 151, 0.5)'}
			background={'rgba(77, 98, 151, 1)'}
			fontSize={'12px'}
			padding={8}
			customCss={{
				textAlign: 'center',
				width: '100%'
			}}
		>
			{ Component }
		</Tooltip>
	)
}

export default PaymentMethod
