import React, { FC, Fragment } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

interface ITransitionProps {
	dependencyKey: any
	timeout?: number,
	transition?: string
}

const Transition: FC<ITransitionProps> = (
	{ dependencyKey,
		children,
		timeout = 500,
		transition = 'fade'
	}) => {
	return (
		<SwitchTransition>
			<CSSTransition
				classNames={transition}
				timeout={timeout}
				key={dependencyKey}
			>
				<Fragment>
					{ children }
				</Fragment>
			</CSSTransition>
		</SwitchTransition>
	)
}

export default Transition
