import React, { FC } from 'react'
import './preloader.scss'

const Preloader: FC = () => {
	return (
		<div className="preloader" />
	)
}

export default Preloader
