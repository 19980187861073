export const TOGGLE_REQUEST_LOADER = 'APP/SHOW_REQUEST_LOADER'
// Прелоадер серверов на главной
export const TOGGLE_SERVERS_LOADER = 'APP/SHOW_SERVERS_LOADER'
export const FETCH_SERVERS = 'APP/FETCH_SERVERS'
// Типы оплаты
export const FETCH_PAYMENTS = 'APP/FETCH_PAYMENTS'
export const PAYMENTS_FETCHING_STATUS = 'APP/PAYMENTS_FETCHING_STATUS'
// Паки
export const FETCH_PACKS = 'APP/FETCH_PACKS'
export const PACKS_FETCHING_STATUS = 'APP/PACKS_FETCHING_STATUS'
// Персы
export const FETCH_ROLES = 'APP/FETCH_ROLES'
export const ROLES_FETCHING_STATUS = 'APP/ROLES_FETCHING_STATUS'
// Fund
export const SET_FUND = 'APP/SET_FUND_HASH'
export const SET_FUND_ENTITY = 'APP/SET_FUND_ENTITY'
export const FUND_FETCHING_STATUS = 'APP/FUND_FETCHING_STATUS'

export const META_FETCHING_STATUS = 'APP/META_FETCHING_STATUS'
export const SET_META = 'APP/SET_META'

export const TOGGLE_FEEDBACK = 'APP/TOGGLE_FEEDBACK'
