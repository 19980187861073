const home = '/'
const login = '/login'
const donate = '/donate'
const donateOnServer = '/donate/:server'
const donateFromGame = '/donate/from/game/:fundHash'
const successfulPayment = '/payment/success'
const failurePayment = '/payment/failure'
const loginToServers = '/donate/auth/:token'
const loginToServer = '/donate/auth/:token/:serverId'
const donateInGame = '/game/donate/:serverId'

export {
	home,
	login,
	donate,
	donateOnServer,
	donateFromGame,
	successfulPayment,
	failurePayment,
	loginToServers,
	loginToServer,
	donateInGame,
}
