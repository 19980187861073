import React, {FC, Fragment, useState, useEffect, useMemo, useLayoutEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useForm} from 'react-hook-form'
import ReactCountUp from 'react-countup'

import Transition from 'components/Transition/Transition'
import Input from 'components/Entities/Form/Input/Input'
import Button from 'components/Entities/Form/Button/Button'
import {getDonatePresets, getUserData} from 'store/user/selectors'
import {getCurrency, getPublicOfferUrl} from "store/app/selectors";
import {useErrorMessage} from 'hooks/useErrorMessage'
import { resetDonatePresets } from "store/user/actions";
import {useBonus} from 'hooks/useBonus'
import {requestDispatch} from 'helpers/request'
import IServer from 'interfaces/IServer'

import {useTranslation} from 'react-i18next';
import Checkbox from "../../../Entities/Form/Checkbox/Checkbox";

interface IFormProps {
  submit: Function,
  onSuccess?: Function,
  server?: IServer,
  disableLogin?: boolean,
  prevSum?: number,
  login?: string;
  email?: string;
  disableSum?: boolean
}

const Form: FC<IFormProps> = (
  {
    submit,
    onSuccess,
    server,
    disableLogin = false,
    prevSum = 0,
    login,
    email,
    disableSum = false
  }) => {
  // Блокирование кнопки (зависимость от запроса)
  const [disable, setDisable] = useState(false)
  const [sumWithBonus, setSumWithBonus] = useState(0)
  // Данные пользователя
  const userData = useSelector(getUserData)
  const currency = useSelector(getCurrency)
  const presets = useSelector(getDonatePresets)

  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    errors,
    formState,
    trigger,
    getValues,
    setError,
    watch,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      login: login ?? userData.login ?? localStorage.getItem('login'),
      email: email ?? userData.email ?? localStorage.getItem('email'),
      sum: prevSum || (presets !== null ? presets.sum : 1000) || 1000,
      server_id: 0,
      agree: true,
    }
  })
  const [errorMessage, setErrorMessage] = useErrorMessage(formState, errors)
  let sum = watch('sum')
  const [calculateBonus] = useBonus()

  useEffect(() => {
    trigger()
  }, [trigger])

  useEffect(() => {
    setSumWithBonus(calculateBonus(sum))
  }, [sum])

	const publicOfferUrl = useSelector(getPublicOfferUrl)

  const onSubmit = async () => {
    const data = getValues()
    if (server) {
      //@ts-ignore
      data.server = server.id
    }
    localStorage.setItem('login', data.login)
    localStorage.setItem('email', data.email)
    localStorage.setItem('sum', '1000')
    setDisable(true)
    try {
      const response: any = await requestDispatch(submit(data), onSubmit);

      if (!response.success) {
        throw {message: response.error_text, errors: response.errors ?? response.error_text ?? 'Unexpected error'}
      }

      const {success, payment_data} = response

      if (success && onSuccess) {
        onSuccess(payment_data, data.login, data.sum)
      }

    } catch ({message, errors}) {
      setDisable(false)
      if (errors) {
        const firstKey = Object.keys(errors)[0]
        if (firstKey) {
          //@ts-ignore
          setError(firstKey, {type: "backendError", message: errors[firstKey]});
        }
      }
    }
  }
  const {t} = useTranslation();

  console.log(presets)

  useLayoutEffect(() => {
    if (presets === null || presets?.sum === null || userData === null) {
      return;
    }

    console.log(presets)

    onSubmit()
    dispatch(resetDonatePresets())
  }, [])

  return (
    <Fragment>
      <div className="donate-main__root">
        <form
          className="donate-main__form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form-group">
            <Input
              id="login"
              name="login"
              label={t('your_login') + ':'}
              autoComplete="off"
              validate={register({required: t('req_login')})}
              disabled={disableLogin}
            />
          </div>
          <div className="form-group">
            <Input
              id="email"
              name="email"
              type="email"
              label={t('your_email') + ':'}
              autoComplete="off"
              validate={
                register({
                  required: t('req_email'),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('incorrect_email')
                  }
                })
              }
            />
          </div>
          <div className="form-group mt-55">
            <div className="input-wrapper">
              <label className="input-label">
                {t('deposit_amount')}
              </label>
              <div className="input-container">
                <input
                  className="input-control input-control_number"
                  name="sum"
                  type="number"
                  autoComplete="off"
                  disabled={disableSum}
                  ref={
                    register({
                      required: t('enter_amount'),
                      min: {
                        value: 1,
                        message: t('minimum_amount')
                      }
                    })
                  }
                />
                <span className="input-ruble">{currency}</span>
              </div>
            </div>
          </div>
          <div className="sum-amount">
            {t('receiving')} <span>
													<ReactCountUp
                            end={sumWithBonus}
                            preserveValue={true}
                            duration={1}
                          /> DP
												</span>
          </div>
          <input
            type="hidden"
            name="server_id"
            value="0"
          />


          <div className="form-group" style={{
						display: 'flex',
						flexFlow: 'row nowrap',
						alignItems: 'center',
						justifyContent: 'center',
						marginTop: '2rem'
					}}>
            <Checkbox
              id="agree"
              name="agree"
              className="login-accepted"
              validate={
                register({
                  required: t('req_terms'),
                  setValueAs: (value) => Boolean(value)
                })
              }
            />
            <span style={{ color: '#fff' }}>
							{t('terms')}

							<a
								href={publicOfferUrl}
								className="footer-nav__link"
								target="_blank"
							>
								{t('terms_accept')}
							</a>
						</span>
          </div>
        </form>
      </div>
      <Button disabled={!formState.isValid || disable} onClick={onSubmit}>
        {t('proceed_payment')}
      </Button>
      <Transition dependencyKey={errorMessage}>
        <p className="donate-error form-error mt-18">
          {errorMessage}
        </p>
      </Transition>
    </Fragment>
  )
}

export default Form
