import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from "react-google-recaptcha"

import Transition from 'components/Transition/Transition'
import { setCaptchaKey, setCaptchaToken } from 'store/captcha/actions'
import { getCaptchaAction, getCaptchaKey } from 'store/captcha/selectors'
import './captcha.scss'

import { useTranslation } from 'react-i18next';

const Captcha: FC = () => {
	const dispatch = useDispatch()
	const captchaKey = useSelector(getCaptchaKey)
	const captchaAction = useSelector(getCaptchaAction)

	const handleSuccess = (captchaToken: string | null) => {
		dispatch(setCaptchaToken(captchaToken))
		dispatch(setCaptchaKey(null))
		captchaAction()
	}

	const { t } = useTranslation();

	return (
		<Transition dependencyKey={captchaKey}>
			{ captchaKey &&
      <div className="captcha">
        <h2 className="captcha__title">
			{t('captcha_title')}
        </h2>
        <ReCAPTCHA
            sitekey={captchaKey}
            onChange={(token) => handleSuccess(token)}
        />
      </div>
			}
		</Transition>
	)
}

export default Captcha
