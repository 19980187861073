import React, { FC } from 'react'
import './title.scss'

interface ITitleProps {
	className?: string,
	gradient?: boolean,
	bg?: boolean,
	bgTitle?: string,
	centered?: boolean,
}

const Title: FC<ITitleProps> = (
	{ gradient= true,
		bg = true,
		bgTitle,
		centered = false,
		className,
		children
	}) => {
	let classNames = 'title '
	classNames += centered ? ' title_centered' :''
	classNames += gradient ? ' title_gradient' : ''
	classNames += bg ? ' title_bg ' : ''
	classNames += className ?? ''

	return (
		<h1
			data-title={bgTitle}
			className={classNames}
		>
			{ children }
		</h1>
	)
}

export default Title
