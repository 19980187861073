import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { Pending as PendingState } from 'components/Entities/Gateway/Pending/Pending'
import { getPacksFetchingStatus, getPaymentsFetchingStatus } from 'store/app/selectors'

import { useTranslation } from 'react-i18next';

const Pending: FC = () => {
	const paymentsStatus = useSelector(getPaymentsFetchingStatus)
	const packsStatus = useSelector(getPacksFetchingStatus)

	const pendingStatus = useMemo(() => {
		if (paymentsStatus === 'pending') {
			return 'payments'
		}
		if (packsStatus === 'pending') {
			return 'packs'
		}
		return 'success'
	}, [paymentsStatus, packsStatus])

	const { t } = useTranslation();

	return (
		<PendingState>
			{ pendingStatus === 'payments' && t('uploading_payment_methods') }
			{ pendingStatus === 'packs' && t('uploading_donation_kits') }
			{ pendingStatus === 'success' && t('switching_payment_form') }
		</PendingState>
	)
}

export default Pending
