import React, { FC, HTMLProps } from 'react'

interface IExternalLinkProps extends HTMLProps<HTMLAnchorElement> {}

const ExternalLink: FC<IExternalLinkProps> = (
	{ href,
		children,
		...restProps
	}) => {
	return (
		<a
			href={href}
			rel="noreferrer"
			target="_blank"
			{ ...restProps}
		>
			{ children }
		</a>
	)
}

export default ExternalLink
