import React, { FC } from 'react'
import { Route } from '../Route/Route'
import Transition from 'components/Transition/Transition'
import { IRouteInterface } from 'interfaces/IRouteInterface'

interface IPrivateRouteProps extends IRouteInterface {
	provider: string | boolean,
	onSuccess: FC,
	onFail: FC
}

const PrivateRoute: FC<IPrivateRouteProps> = (
	{ provider,
		onSuccess,
		onFail,
		...restProps
	}) => {
	return (
		<Transition dependencyKey={provider}>
			{ provider === true && <Route component={onSuccess} { ...restProps } /> }
			{ provider === false && <Route component={onFail} { ...restProps } /> }
		</Transition>
	)
}

export default PrivateRoute
