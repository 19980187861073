import React, { FC, useMemo } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Route } from 'components/Router/Route/Route'
import WithLogin from '../WithLogin/WithLogin'
import PrivateRoute from '../PrivateRoute/PrivateRoute'
import { getUserStatus } from 'store/user/selectors'
import Home from 'pages/Home'
import Login from 'pages/Login'
import Guest from 'pages/Donate/Guest/Donate'
import Server from 'pages/Donate/User/Server'
import Servers from 'pages/Donate/User/Servers'
import Game from 'pages/Donate/Game/Game'
import PaymentSuccess from 'pages/Payment/PaymentSuccess'
import PaymentFailure from 'pages/Payment/PaymentFailure'
import ToServer from 'pages/Autologin/ToServer'
import ToServers from 'pages/Autologin/ToServers'

import {
	home,
	login,
	donate,
	donateOnServer,
	donateFromGame,
	successfulPayment,
	failurePayment,
	loginToServers,
	loginToServer,
} from 'router/routes'

import { useTranslation } from 'react-i18next';

const Controller: FC = () => {
	const authenticated = useSelector(getUserStatus)
	const projectName = useMemo(() => {
		return 'GTA5RP GAME'
	}, [])

	const { t } = useTranslation();

	return (
		<Switch>
			<Route
				title={`${projectName} - ${t('home_subtitle')}`}
				path={home}
				component={Home}
				exact
			/>
			<PrivateRoute
				provider={authenticated}
				title={`${projectName} - ${t('authorization_title')}`}
				path={login}
				onSuccess={() => <Redirect to={home} />}
				onFail={Login}
			/>
			<Route
				title={`${projectName} - ${t('donate')}`}
				path={loginToServers}
				component={ToServers}
				exact
			/>
			<Route
				title={`${projectName} - ${t('donate')}`}
				path={loginToServer}
				component={ToServer}
				exact
			/>
			<Route
				title={`${projectName} - ${t('donate')}`}
				path={donateFromGame}
				component={Game}
				exact
			/>

			<WithLogin
				provider={authenticated}
				path={donateOnServer}
				exact={true}
				component={
					<PrivateRoute
						provider={authenticated}
						title={`${projectName} - ${t('donate')}`}
						onSuccess={Server}
						onFail={() => <Redirect to={login} />}
					/>
				}
			/>
			<WithLogin
				provider={authenticated}
				path={donate}
				component={
					<PrivateRoute
						provider={authenticated}
						title={`${projectName} - ${t('donate')}`}
						onSuccess={Servers}
						onFail={Guest}
					/>
				}
			/>

			<Route
				title={`${projectName} - ${t('home_subtitle')}`}
				path={successfulPayment}
				component={PaymentSuccess}
			/>

			<Route
				title={`${projectName} - ${t('home_subtitle')}`}
				path={failurePayment}
				component={PaymentFailure}
			/>

		</Switch>
	)
}

export {
	Controller
}
