import React, { FC } from 'react'

const Lines: FC = () => {
	return (
		<div className="page-lines">
			<div className="page-lines__line page-lines__line_1"/>
			<div className="page-lines__line page-lines__line_2"/>
			<div className="page-lines__line page-lines__line_3"/>
			<div className="page-lines__line page-lines__line_4"/>
			<div className="page-lines__line page-lines__line_5"/>
			<div className="page-lines__line page-lines__line_6"/>
		</div>
	)
}

export default Lines
