import { combineReducers } from 'redux'
import { appReducer } from './app/appReducer'
import { userReducer } from './user/userReducer'
import { captchaReducer } from './captcha/captchaReducer'

export const rootReducer = combineReducers({
	app: appReducer,
	user: userReducer,
	captcha: captchaReducer,
})
