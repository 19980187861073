import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'
import { request } from 'helpers/request'
import { SET_USER_DATA, SET_USER_STATUS, SET_USER_TOKEN, SET_DONATE_PRESETS } from './types'
import { ISetUserData, ISetUserStatus, ISetUserToken, ISetDonatePresets, IResetDonatePresets } from './types/actions'
import IUserData from 'interfaces/IUserData'
import { toggleRequestLoader } from '../app/actions'

export const setUserData = (data: IUserData): ISetUserData => {
	return {
		type: SET_USER_DATA,
		payload: data
	}
}

export const setUserStatus = (isAuth: boolean): ISetUserStatus => {
	return {
		type: SET_USER_STATUS,
		payload: isAuth
	}
}

export const setUserToken = (token: string | null): ISetUserToken => {
	if (token) {
		const now = Date.now() + 600000
		localStorage.setItem('token', token)
		localStorage.setItem('tokenExpire', String(now))
	}
	return {
		type: SET_USER_TOKEN,
		payload: token
	}
}

export const setDonatePresets = (sum: number): ISetDonatePresets => {
	return {
		type: SET_DONATE_PRESETS,
		payload: sum
	}
}

export const resetDonatePresets = (): IResetDonatePresets => {
	return {
		type: SET_DONATE_PRESETS,
		payload: null
	}
}

export const loginByPassword = (data: any) => {
	return async (dispatch: ThunkDispatch<IUserData, void, Action>) => {
		return await request('auth/authByPassword', 'POST', data)
	}
}

export const fetchProfile = () => {
	return async (dispatch: ThunkDispatch<IUserData, void, Action>) => {
		dispatch(toggleRequestLoader(true))
		try {
			const response: any = await request('auth/check', 'POST')
			if (!response.success) {
				dispatch(toggleRequestLoader(false))
				return response
			}
			dispatch(setUserData({
				'login': response.login,
				'email': response.email,
				'donate': response.donate
			}))
			dispatch(setUserStatus(true))
		} catch (e) {}
		dispatch(toggleRequestLoader(false))
	}
}

export const forceLogout = () => {
	//@ts-ignore
	return dispatch => {
		localStorage.removeItem('token')
		localStorage.removeItem('tokenExpire')
		dispatch(setUserStatus(false))
		dispatch(setUserData({
			login: null,
			email: null,
			donate: null,
		}))
		dispatch(setUserToken(null)	)
	}
}

export const loginUser = () => {
	return async (dispatch: ThunkDispatch<IUserData, void, Action>) => {
		return await dispatch(fetchProfile())
	}
}

export const hydrateToken = () => {
	const now = Date.now()
	let token = localStorage.getItem('token')
	const tokenExpire = localStorage.getItem('tokenExpire')

	// @ts-ignore
	if (tokenExpire && tokenExpire < now) {
		return forceLogout()
	}

	return setUserToken(token)
}
