import React, {FC, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {getFund, getFundHash} from 'store/app/selectors'
import { gamePayment } from 'store/app/actions'
import { useBonus } from 'hooks/useBonus'
import { IPaymentState } from 'interfaces/IPaymentState'
import './game.scss'
import Form from "../Form/Form";
import Confirm from 'components/Donate/Confirm/Confirm'

const Game: FC = () => {
	const dispatch = useDispatch()
	const fund = useSelector(getFund)

	const fundHash = useSelector(getFundHash)
	const [ calculateBonus ] = useBonus()
	const paymentMethods = fund.methods

	const [ paymentState, setPaymentState ] = useState<IPaymentState>({
		key: fund.entity.key,
		login: fund.entity.name,
		sum: fund.entity.sum,
		sumWithBonus: calculateBonus(fund.entity.sum),
		email: fund.entity.email
	})

	const handlePayment = async (
		id: string
	) => {
		const data = {
			fund_hash: '',
			payment_method: '',
			email: '',
		}

		data.fund_hash = fundHash
		data.payment_method = id
		data.email = paymentState.email

		try {
			// alert(JSON.stringify(data))
			const Z: any = await dispatch(gamePayment(data))
			// alert(JSON.stringify(Z))

			// alert(JSON.stringify(Z))

			const { success, link } = Z

			if (success) {
				window.location.href = link
			}
		} catch (e) {
			// alert(JSON.stringify(e))
		}
	}

	return (
		<div className="donate-game">
			{paymentState.email === null && (
				<div style={{maxWidth: '600px', width: '100%', margin: '0 auto'}}>
					<Form
						server={fund.entity.server}
						disableLogin={true}
						prevSum={fund.entity.sum}
						login={fund.entity.name}
						email={fund.entity.email === null ? '' : fund.entity.email}
						submit={({ email }) => setPaymentState((prevState) => ({
							...prevState,
							email
						}))}
						disableSum={true}
					/>
				</div>
			)}
			{paymentState.email !== null && (
					<Confirm
						handlePaymentClick={handlePayment}
						paymentState={paymentState}
						paymentMethods={paymentMethods}
						handleBack={null}
						sum={paymentState.sum}
					/>
			)}

		</div>
	)
}

export {
	Game
}
