import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { loginUser } from 'store/user/actions'
import { getUserToken } from 'store/user/selectors'
import { fetchPayments } from 'store/app/actions'

export const useTokenControl = () => {
	const dispatch = useDispatch()
	const token = useSelector(getUserToken)
	const location = useLocation()
	const history = useHistory()

	useEffect(() => {
		(async function () {
			dispatch(fetchPayments())
			if (token) {
				const response: any = await dispatch(loginUser())
				if (!response) return
				if (!response.success) {
					const path = location.pathname
					if (path !== '/') {
						history.push('/login')
					}
				}
			}
		})()
	}, [dispatch, token])
}
