import React, { ButtonHTMLAttributes, FC } from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

const Button: FC<ButtonProps> = ({ className, children, ...restProps}) => {
	let classNames = `btn btn-control `
	classNames += className ?? ''
	return (
		<button className={classNames} { ...restProps }>
			{ children }
			<div className="btn-hover btn-hover_top-left" />
			<div className="btn-hover btn-hover_top-right" />
			<div className="btn-hover btn-hover_bottom-right" />
			<div className="btn-hover btn-hover_bottom-left" />
		</button>
	)
}

export default Button
