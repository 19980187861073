import React, { FC } from 'react'
import './serverFooter.scss'

const ServerFooter: FC = ({ children }) => {
	return (
		<footer className="server-footer">
			{ children }
		</footer>
	)
}

export default ServerFooter
