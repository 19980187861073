import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getServerList, getServersStatus } from 'store/app/selectors'
import { fetchServers } from 'store/app/actions'
import { useFetch } from 'hooks/useFetch'

import Title from 'components/Template/Title/Title'
import Controller from 'components/Entities/Gateway/Controller/Controller'
import Fail from 'components/Pages/Servers/Fail/Fail'
import Pending from 'components/Pages/Servers/Pending/Pending'
import Success from './Success/Success'

import { useTranslation } from 'react-i18next';

const Servers: FC = () => {
	const servers = useSelector(getServerList)
	const fetchServersStatus = useSelector(getServersStatus)
	useFetch(fetchServers, servers)

	const { t } = useTranslation();

	return (
		<div className="home-servers" id="servers">
			<Title
				bgTitle={t('our_servers')}
				className="home-servers__title"
				centered={true}
			>
				{t('our_game_servers')}
			</Title>
			<div className="home-servers__container">
				<Controller
					onWaiting={<Pending />}
					onError={<Fail />}
					onSuccess={<Success />}
					dependencyKey={fetchServersStatus}
				/>
			</div>
		</div>
	)
}
export default Servers
