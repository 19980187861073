import React, {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useForm } from 'react-hook-form'
import Transition from 'components/Transition/Transition'
import Title from 'components/Template/Title/Title'
import FormInput from 'components/Form/FormInput/FormInput'
import Button from 'components/Entities/Form/Button/Button'
import Checkbox from 'components/Entities/Form/Checkbox/Checkbox'
import { requestDispatch } from 'helpers/request'
import { loginByPassword } from 'store/user/actions'
import { toggleRequestLoader } from 'store/app/actions'
import { useErrorMessage } from 'hooks/useErrorMessage'

import { useTranslation } from 'react-i18next';
import {getPublicOfferUrl} from "../../../store/app/selectors";

interface ILoginProps {
	onLoginSuccess: Function,
	onUserConfirm: Function
}

const Login: FC<ILoginProps> = ({ onLoginSuccess, onUserConfirm }) => {
	const dispatch = useDispatch()
	const publicOfferUrl = useSelector(getPublicOfferUrl)
	const {
		register,
		handleSubmit,
		errors,
		formState,
		trigger,
		getValues,
		setError,
	} = useForm({
		mode: "onChange",
		criteriaMode: "all",
		defaultValues: {
			login: null,
			password: null,
			agree: true,
		}
	})
	const [ errorMessage, setErrorMessage ] = useErrorMessage(formState, errors)
	const [ isSent, setIsSent ] = useState<Boolean>(false)

	useEffect(() => {
		trigger()
	}, [trigger])

	const loginRequest = async () => {
		dispatch(toggleRequestLoader(true))
		setIsSent(true)
		try {
			const data = getValues()
			// @ts-ignore
			data.remember = 0
			const response: any = await requestDispatch(loginByPassword(data), loginRequest)
			if (!response.success) {
				throw { message: response.error_text, errors: response.errors, response }
			} else {
				onLoginSuccess(response.token)
			}
		} catch ({ message, errors, response }) {
			if (errors) {
				const firstKey = Object.keys(errors)[0]
				if (firstKey) {
					//@ts-ignore
					setError(firstKey, { type: "backendError", message: errors[firstKey] });
				}
			}
			if (message !== 'need_authcheck') {
				// @ts-ignore
				setErrorMessage(message)
				setIsSent(false)
				dispatch(toggleRequestLoader(false))
				return
			}
			setError('login', { type: "invalidLogin", message: "Confirmation required" });
			onUserConfirm(response['2fa_rid'])
		}
		setIsSent(false)
		dispatch(toggleRequestLoader(false))
	}

	const onSubmit = async () => {
		await loginRequest()
	}

	const { t } = useTranslation();

	return (
		<div>
			<Title
				centered={true}
				bgTitle={t('authorization_title')}
				className="login-title"
			>
				{t('authorization_title')}
			</Title>
			<form className="form login-form" onSubmit={handleSubmit(onSubmit)}>
				<div className="login-form__wrapper">
					<FormInput
						id="login"
						name="login"
						autoComplete="off"
						label={t('login')}
						validate={register({ required: t('req_login_fill') })}
					/>
					<FormInput
						id="password"
						name="password"
						type="password"
						label={t('password')}
						autoComplete="off"
						validate={register({ required: t('req_password_fill') })}
					/>
					<Transition dependencyKey={errorMessage}>
						<p className="form-error mt-18">
							{ errorMessage }
						</p>
					</Transition>
					<div className="form-group login-form__btn-group">
						{ /* @ts-ignore */ }
						<Button disabled={!formState.isValid || isSent}>
							{t('enter')}
						</Button>
					</div>
				</div>
				{/*<div className="form-group form-group_centered login-accepted__wrapper">*/}
				{/*	<Checkbox*/}
				{/*		id="agree"*/}
				{/*		name="agree"*/}
				{/*		className="login-accepted"*/}
				{/*		validate={*/}
				{/*			register({*/}
				{/*				required: t('req_terms'),*/}
				{/*				setValueAs: (value) => Boolean(value)*/}
				{/*			})*/}
				{/*		}*/}
				{/*	/>*/}
				{/*	<span>*/}
				{/*	 {t('terms')}*/}

				{/*		<a*/}
				{/*			href={publicOfferUrl}*/}
				{/*			className="footer-nav__link"*/}
				{/*			target="_blank"*/}
				{/*		>*/}
				{/*			{t('terms_accept')}*/}
				{/*		</a>*/}
				{/*	</span>*/}
				{/*</div>*/}
			</form>
		</div>
	)
}

export {
	Login
}
