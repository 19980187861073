import React, { FC } from 'react'
import Preloader from 'components/Entities/Preloader/Preloader'
import './pendingProfile.scss'

import { useTranslation } from 'react-i18next';

const PendingProfile: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="user-pending">
			<Preloader />
			<span>
				{t('pending_profile')}
			</span>
		</div>
	)
}

export default PendingProfile
