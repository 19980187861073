import React, { FC, ReactNode } from 'react'
import { Route } from 'react-router-dom'
import Transition from 'components/Transition/Transition'
import PendingProfile from 'components/PendingProfile/PendingProfile'

interface IWithLoginProps {
	component: ReactNode,
	provider: string | boolean,
	path: string,
	exact?: boolean
}

const WithLogin: FC<IWithLoginProps> = (
	{ component,
		provider,
		path,
		exact = false
	}) => {
	return (
		<Route path={path} render={() => (
			<Transition dependencyKey={provider}>
				{ provider === 'pending' && <PendingProfile /> }
				{ provider !== 'pending' && component }
			</Transition>
		)} exact={exact} />
	)
}

export default WithLogin
