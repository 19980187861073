import React, { FC, InputHTMLAttributes } from 'react'
import Input from 'components/Entities/Form/Input/Input'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string,
	validate?: any,
}

const FormInput: FC<InputProps> = (props) => {
	return (
		<div className="form-group">
			<Input { ...props } />
		</div>
	)
}

export default FormInput
