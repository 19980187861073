import React, { FC, useEffect, useMemo } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PendingProfile from 'components/PendingProfile/PendingProfile'
import { setUserToken, setDonatePresets } from 'store/user/actions'
import { getUserStatus } from 'store/user/selectors'

interface IToServersProps {
	token: string
}

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ToServers: FC = () => {
	const { token } = useParams<IToServersProps>()
	const dispatch = useDispatch()
	const history = useHistory()
	const isAuthenticated = useSelector(getUserStatus)

	const query = useQuery()

	useEffect(() => {
		dispatch(setUserToken(token))

		const sum = Number(query.get('sum') ?? 'null')

		if (!isNaN(sum)) {
			console.log('not nan')
			dispatch(setDonatePresets(sum))
		}
	}, [])

	useEffect(() => {
		if (isAuthenticated === true) {
			history.push('/donate')
		}
	}, [isAuthenticated])

	return (
		<PendingProfile />
	)
}

export default ToServers
