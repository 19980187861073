import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RequestStatusType } from 'types/RequestStatusType'
import store from 'store/store'

export const useRequestChain = (scenario: any) => {
	const dispatch = useDispatch()
	let [ chainResult, setChainResult ] = useState('pending')

	useEffect(() => {
		(async function () {
			for(let i = 0; i < scenario.length; i++) {
				const [ status, callback ] = scenario[i]
				const requestStatus:RequestStatusType = status(store.getState())
				if (requestStatus === 'success') {
					continue;
				}
				await dispatch(callback())
				if (requestStatus === 'failure') {
					setChainResult('failure')
					break;
				}
			}
			if (chainResult !== 'failure') {
				setChainResult('success')
			}
		})()
	}, [dispatch])

	return chainResult
}
