import React, { FC } from 'react'

interface ICardItemProps {
	name: string,
	value: string | number
}

const CardItem: FC<ICardItemProps> = ({ name, value}) => (
	<div className="donate-card__item">
		<p className="donate-card__type">
			{ name }
		</p>
		<p className="donate-card__value">
			{ value }
		</p>
	</div>
)

export default CardItem
