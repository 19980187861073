import React, { FC } from 'react'
import { useSelector } from 'react-redux';
import Preloader from 'components/Entities/Preloader/Preloader'
import Transition from 'components/Transition/Transition'
import { getRequestLoader } from 'store/app/selectors'
import './systemPreloader.scss'

const SystemPreloader: FC = () => {
	const requestLoader = useSelector(getRequestLoader)

	return (
		<Transition dependencyKey={requestLoader}>
			{ requestLoader &&
				<div className="systemPreloader">
					<Preloader />
				</div>
			}
		</Transition>
	)
}

export default SystemPreloader
