import React, { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Game as DonateGame } from 'components/Pages/Donate/Game/Game'
import Controller from 'components/Entities/Gateway/Controller/Controller'
import Pending from 'components/Pages/Donate/Game/Pending/Pending'
import Fail from 'components/Pages/Donate/Game/Fail/Fail'
import { useRequestChain } from 'hooks/useRequestChain'
import { getFundScenario } from 'store/app/scenarios'
import {useDispatch} from 'react-redux';
import {setFund} from '../../../store/app/actions';

interface IGameProps {
	fundHash: string
}

const Game: FC = () => {
	const { fundHash } = useParams<IGameProps>()
	const dispatch = useDispatch()
	dispatch(setFund(fundHash))
	const chainStatus = useRequestChain(getFundScenario())

	return (
		<Controller
			onWaiting={<Pending />}
			onError={<Fail />}
			onSuccess={<DonateGame />}
			dependencyKey={chainStatus}
		/>
	)
}

export default Game
