import React, { FC, useState, Fragment, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Transition from 'components/Transition/Transition'
import { Login as LoginStep } from 'components/Pages/Login/Login'
import { Confirm as ConfirmStep } from 'components/Pages/Login/Confirm'
import { setUserToken } from 'store/user/actions'
import { donate } from 'router/routes'

const Login: FC = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [ loginStatus, setLoginStatus ] = useState('login')
	const [ confirmKey, setConfirmKey ] = useState('')

	const onLoginSuccess = useCallback(() => {
		return async (token: string) => {
			await dispatch(setUserToken(token))
			history.push(donate)
		}
	}, [])

	const onUserConfirm = useCallback(() => {
		return (confirmKey: string) => {
			setLoginStatus('confirm')
			setConfirmKey(confirmKey)
		}
	}, [])

	return (
		<Fragment>
			<Transition dependencyKey={loginStatus}>
				{ loginStatus === 'login' &&
					<LoginStep
							onLoginSuccess={onLoginSuccess()}
							onUserConfirm={onUserConfirm()}
					/>
				}
				{ loginStatus === 'confirm' &&
					<ConfirmStep
							onLoginSuccess={onLoginSuccess()}
							confirmKey={confirmKey}
					/>
				}
			</Transition>
			<div className="login-el" />
			<div className="login-roles" />
		</Fragment>
	)
}

export default Login
