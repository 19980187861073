import React, { FC } from 'react'
import './serverRoot.scss'

const ServerRoot: FC = ({ children, ...restProps }) => {
	return (
		<section className="server-root" { ...restProps }>
			{ children }
		</section>
	)
}

export default ServerRoot
