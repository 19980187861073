import IState from './types/IState'

const getState = (state: any) => state.app

export const getRequestLoader = (state: IState) => getState(state).requestLoader
export const getServersStatus = (state: IState) => getState(state).serversLoading
export const getServerList = (state: IState) => getState(state).servers
export const getPaymentsList = (state: IState) => getState(state).payments
export const getPaymentsFetchingStatus = (state: IState) => getState(state).paymentsFetchingStatus
export const getPacksList = (state: IState) => getState(state).packs
export const getPacksFetchingStatus = (state: IState) => getState(state).packsFetchingStatus
export const getRolesList = (state: IState) => getState(state).roles
export const getRolesFetchingStatus = (state: IState) => getState(state).rolesFetchingStatus
export const getFeedback = (state: IState) => getState(state).feedback
export const getServices = (state: IState) => getState(state).services
export const getServicesOther = (state: IState) => getState(state).services_other
export const getFundFetchingStatus = (state: IState) => getState(state).fundFetchingStatus
export const getFund = (state: IState) => getState(state).fund
export const getFundHash = (state: IState) => getState(state).fundHash
export const getMetaFetchingStatus = (state: IState) => getState(state).metaFetchingStatus
export const getPublicOfferUrl = (state: IState) => getState(state).public_offer_url
export const getPolicyUrl = (state: IState) => getState(state).policy_url
export const getCurrency = (state: IState) => getState(state).currency
export const getLocale = (state: IState) => getState(state).locale