import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import Title from 'components/Template/Title/Title'
import Button from 'components/Entities/Form/Button/Button'

import { useTranslation } from 'react-i18next';

const PaymentFailure: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="payment-status">
			<Title
				gradient={true}
				bg={true}
				bgTitle={t('payment_failure')}
				centered={true}
				className="payment-status__title"
			>
				{t('payment_failure')}
			</Title>
			<p className="payment-status__text">
				{t('payment_status_text')}
			</p>
			<div className="payment-status__btn">
				<NavLink to="/donate">
					<Button>
						{t('payment_button')}
					</Button>
				</NavLink>
			</div>
		</div>
	)
}

export default PaymentFailure
