import React, { FC, useMemo } from 'react'
import './serverHeader.scss'

interface IServerHeaderProps {
	id: number,
	name: string,
}

const ServerHeader: FC<IServerHeaderProps> = ({ id, name, children }) => {
	const serverId = useMemo(() => {
		return String(id).padStart(2, "0")
	}, [id])

	return (
		<header className="server-header">
			<span className="server-header__number">
				{ serverId }
			</span>
			<div className="server-header__container">
				<h2 className="server-header__name">
					{ name }
				</h2>
				{ children &&
					<div className="server-header__inner">
						{ children }
					</div>
				}
			</div>
		</header>
	)
}

export default ServerHeader
