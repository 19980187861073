import React, {FC, useCallback, useMemo} from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Server as ServerEntity } from 'components/Entities/Server/Server'
import { donate } from 'router/routes'
import { useEnumerate } from 'hooks/useEnumerate'
import { roles as enumeratedRoles } from 'appearance/enumerate'
import './server.scss'

import { useTranslation } from 'react-i18next';

interface IServerHeaderProps {
	roles: any
}

const Header: FC<IServerHeaderProps> = ({ roles }) => {
	const enumerated = useEnumerate(roles.length, enumeratedRoles)

	const { t } = useTranslation();

	return (
		<p className="server__roles">
			{ roles.length === 0 ? t('empty_characters') : enumerated }
		</p>
	)
}

interface IContainerProps {
	roles: any,
}

const Container:FC<IContainerProps> = ({ roles }) => {
	return (
		<div className="server-roles">
			{ roles.map((role: any) => (
				<div key={role.mid} className="server-role">
					<div className="server-role__level">
						<span>
							{ role.level }
						</span>
					</div>
				 	<p>{ role.name }</p>
				</div>
			))}
		</div>
	)
}

interface IServerFooterProps {
	route: string,
}

const Footer: FC<IServerFooterProps> = ({ route }) => {
	const { t } = useTranslation();

	return (
		<Link to={`${route}/downtown`} className="server__btn">
			{t('replenish_account')}
		</Link>
	)
}

interface IServerProps {
	id: number,
	name: string,
	background: string,
	roles?: any
}

const Server: FC<IServerProps> = (
	{ id,
		name,
		background,
		roles,
	}) => {
	const history = useHistory()

	const handleClick = useCallback(() => {
		return () => {
			history.push(`/donate/${name.toLowerCase()}`)
		}
	}, [])

	const serverRoles = useMemo(() => {
		return roles.reduce((accumulator: [], serverItem: any) => {
			return serverItem.server === id ? [ ...accumulator, serverItem ] : accumulator
		}, [])
	}, [roles])

	return (
		<ServerEntity
			serverId={id}
			serverName={name}
			background={background}
			toHeader={<Header roles={serverRoles} />}
			toContainer={<Container roles={serverRoles} />}
			toFooter={<Footer route={donate} />}
			onClick={handleClick()}
		/>
	)
}

export default Server
