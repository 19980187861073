import {
	fetchPayments,
	fetchPacks,
	fetchRoles,
	fetchServers,
	fetchFundInfo
} from './actions'
import {
	getPaymentsFetchingStatus,
	getPacksFetchingStatus,
	getRolesFetchingStatus,
	getServersStatus,
	getFundFetchingStatus,
} from './selectors';

export const paymentsScenario = () => {
	return [ getPaymentsFetchingStatus, fetchPayments ]
}

export const packsScenario = () => {
	return [ getPacksFetchingStatus, fetchPacks ]
}

export const rolesScenario = () => {
	return [ getRolesFetchingStatus, fetchRoles ]
}

export const serversScenario = () => {
	return [ getServersStatus, fetchServers ]
}

export const fundScenario = () => {
	return [ getFundFetchingStatus, fetchFundInfo ]
}

export const serverListScenario = () => {
	return [
		serversScenario(),
		rolesScenario(),
	]
}

export const donateScenario = () => {
	return [
		paymentsScenario(),
		packsScenario()
	]
}

export const userDonateScenario = () => {
	return [
		paymentsScenario(),
		packsScenario(),
		rolesScenario(),
	]
}

export const getFundScenario = () => {
	return [
		fundScenario(),
	]
}
