import React, { FC, Fragment } from 'react'

import Title from 'components/Template/Title/Title'
import Fail from 'components/Pages/Servers/Fail/Fail'
import Pending from 'components/Pages/Servers/Pending/Pending'
import Success from 'components/Pages/Servers/Success/Success'
import Controller from 'components/Entities/Gateway/Controller/Controller'
import { useRequestChain } from 'hooks/useRequestChain'
import { serverListScenario } from 'store/app/scenarios'

import { useTranslation } from 'react-i18next';

const Servers: FC = () => {
	const serversScenario = useRequestChain(serverListScenario())

	const { t } = useTranslation();

	return (
		<Fragment>
			<Title className="servers__title" gradient={true}>
				{t('servers_title_1')}<br />
				{t('servers_title_2')}
			</Title>
			<Controller
				onWaiting={<Pending />}
				onError={<Fail />}
				onSuccess={<Success />}
				dependencyKey={serversScenario}
			/>
		</Fragment>
	)
}

export default Servers
