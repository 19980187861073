import React, { FC, useState } from 'react'
import Transition from 'components/Transition/Transition'
import './scrollToTop.scss'

import { useTranslation } from 'react-i18next';

const ScrollToTop: FC = () => {
	const [ show, setShow ] = useState(false)

	const handleClick = () => {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}

	window.addEventListener('scroll', (e) => {
		const height = window.innerHeight
		if (height / 2 < window.pageYOffset) {
			setShow(true)
		} else {
			setShow(false)
		}
	})

	const { t } = useTranslation();

	return (
		<Transition dependencyKey={show} transition="fromLeft">
			{ show &&
				<div className="scroll-top" onClick={handleClick}>
					<div className="scroll-top__container">
						<i className="icon icon__top-arrow scroll-top__arrow" />
						<span>{t('top_scroll')}</span>
					</div>
				</div>
			}
		</Transition>
	)
}

export default ScrollToTop
