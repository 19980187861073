import {Action} from 'redux';
import {ThunkDispatch} from 'redux-thunk'
import IState from './types/IState'
import {
	FETCH_PACKS,
	FETCH_PAYMENTS,
	FETCH_ROLES,
	FETCH_SERVERS,
	FUND_FETCHING_STATUS,
	PACKS_FETCHING_STATUS,
	PAYMENTS_FETCHING_STATUS,
	ROLES_FETCHING_STATUS,
	SET_FUND,
	SET_FUND_ENTITY,
	TOGGLE_FEEDBACK,
	TOGGLE_REQUEST_LOADER,
	TOGGLE_SERVERS_LOADER,
	META_FETCHING_STATUS,
	SET_META,
} from './types'
import {
	IFundFetchingStatus, IMetaFetchingStatus,
	IPacksFetchingStatus,
	IPaymentsFetchingStatus,
	IRolesFetchingStatus,
	ISetFund,
	ISetFundEntity,
	ISetServersLoading,
	IToggleFeedback,
	IToggleRequestLoader
} from './types/actions'
import {RequestStatusType} from 'types/RequestStatusType'
import {request} from 'helpers/request'
import IUserData from '../../interfaces/IUserData';
import { setUserToken } from '../user/actions'

// Получение серверов от API
export const fetchServers = (): (dispatch: ThunkDispatch<IState, void, Action>) => Promise<void> => {
	return async (dispatch: ThunkDispatch<IState, void, Action>) => {
		dispatch(toggleServersLoader('pending'))
		try {
			const response = await fetch('https://gta5rp.com/api/stats?v=2')
			const { servers } = await response.json()

			dispatch({ type: FETCH_SERVERS, payload: servers })
			dispatch(toggleServersLoader('success'))
		} catch (e) {
			dispatch(toggleServersLoader('failure'))
		}
	}
}

export const toggleRequestLoader = (status: boolean): IToggleRequestLoader => {
	return {
		type: TOGGLE_REQUEST_LOADER,
		payload: status,
	}
}

export const toggleServersLoader = (status: RequestStatusType): ISetServersLoading => {
	return {
		type: TOGGLE_SERVERS_LOADER,
		payload: status,
	}
}

export const paymentsFetchingStatus = (status: RequestStatusType): IPaymentsFetchingStatus => {
	return {
		type: PAYMENTS_FETCHING_STATUS,
		payload: status,
	}
}

// Получение видов оплаты
export const fetchPayments = (): (dispatch: ThunkDispatch<IState, void, Action>) => Promise<void> => {
	return async (dispatch: ThunkDispatch<IState, void, Action>) => {
		dispatch(paymentsFetchingStatus('pending'))
		try {
			const { success, methods } = await request('donate/paymentMethods?v=2', 'POST')
			if (success) {
				dispatch({ type: FETCH_PAYMENTS, payload: methods })
				await dispatch(paymentsFetchingStatus('success'))
			} else {
				dispatch(paymentsFetchingStatus('failure'))
			}
		} catch (e) {
			dispatch(paymentsFetchingStatus('failure'))
		}
	}
}

export const packsFetchingStatus = (status: RequestStatusType): IPacksFetchingStatus => {
	return {
		type: PACKS_FETCHING_STATUS,
		payload: status,
	}
}

// Получение видов оплаты
export const fetchPacks = (): (dispatch: ThunkDispatch<IState, void, Action>) => Promise<void> => {
	return async (dispatch: ThunkDispatch<IState, void, Action>) => {
		dispatch(packsFetchingStatus('pending'))
		try {
			const response = await fetch('https://api.gta5rp.com/donate/packList')
			const { success, packs } = await response.json()
			if (success) {
				dispatch({ type: FETCH_PACKS, payload: packs })
				dispatch(packsFetchingStatus('success'))
				return
			}
			dispatch(packsFetchingStatus('failure'))
		} catch (e) {
			dispatch(packsFetchingStatus('failure'))
		}
	}
}

// Оплата
export const guestPayment = (data: any, successKey: boolean = false) => {
	return async (dispatch: ThunkDispatch<IState, void, Action>) => {
		if (!successKey) {
			data.check = 1
		} else {
			data.check = 0
		}
		return await request('donate/newInvoice', 'POST', data)
	}
}

// Оплата
export const userPayment = (data: any, successKey:boolean = false) => {
	return async (dispatch: ThunkDispatch<IState, void, Action>) => {
		if (!successKey) {
			data.check = 1
		} else {
			data.check = 0
		}
		return await request('donate/newPayMember', 'POST', data)
	}
}

// Оплата
export const packPayment = (data: any, successKey: boolean = false) => {
	return async (dispatch: ThunkDispatch<IState, void, Action>) => {
		if (!successKey) {
			data.check = 1
		} else {
			data.check = 0
		}
		return await request('donate/newMemberDonatePackPay', 'POST', data)
	}
}

export const rolesFetchingStatus = (status: RequestStatusType): IRolesFetchingStatus => {
	return {
		type: ROLES_FETCHING_STATUS,
		payload: status,
	}
}

// Персы
export const fetchRoles = (): (dispatch: ThunkDispatch<IState, void, Action>) => Promise<void> => {
	return async (dispatch: ThunkDispatch<IState, void, Action>) => {
		dispatch(rolesFetchingStatus('pending'))
		try {
			const { success, chars } = await request('member/chars', 'POST')
			if (success) {
				dispatch({ type: FETCH_ROLES, payload: chars })
				dispatch(rolesFetchingStatus('success'))
				return
			}
			dispatch(rolesFetchingStatus('failure'))
		} catch (e) {
			dispatch(rolesFetchingStatus('failure'))
		}
	}
}

/**
 *
 * @meta
 */
export const metaFetchingStatus = (status: RequestStatusType): IMetaFetchingStatus => {
	return {
		type: META_FETCHING_STATUS,
		payload: status,
	}
}

export const fetchMeta = (fromGame: string | null = null): (dispatch: ThunkDispatch<IState, void, Action>) => Promise<void> => {
	return async (dispatch: ThunkDispatch<IState, void, Action>) => {
		dispatch(metaFetchingStatus('pending'))
		try {
			const response = await fetch(fromGame === null
				? 'https://api.gta5rp.com/misc/donateSiteMeta'
				: 'https://api.gta5rp.com/misc/donateSiteMeta?from=game')
			const { success, currency, public_offer_url, policy_url, locale } = await response.json()


			if (success) {
				dispatch({ type: SET_META, payload: { currency, public_offer_url, policy_url, locale } })
				dispatch(metaFetchingStatus('success'))
				return
			}

			dispatch(metaFetchingStatus('failure'))
		} catch (e) {
			dispatch(metaFetchingStatus('failure'))
		}
	}
}

export const toggleFeedback = (status: boolean): IToggleFeedback => {
	return {
		type: TOGGLE_FEEDBACK,
		payload: status,
	}
}

export const sendFeedback = (data: any) => {
	return async (dispatch: ThunkDispatch<IUserData, void, Action>) => {
		return await request('support/newTicket', 'POST', data)
	}
}

export const fundFetchingStatus = (status: RequestStatusType): IFundFetchingStatus => {

	return {
		type: FUND_FETCHING_STATUS,
		payload: status,
	}
}

export const setFund = (hash: string): ISetFund => {
	return {
		type: SET_FUND,
		payload: hash,
	}
}

export const setFundEntity = (entity: any): ISetFundEntity => {
	return {
		type: SET_FUND_ENTITY,
		payload: entity
	}
}

// Персы
export const fetchFundInfo = (fundHash: string): (dispatch: ThunkDispatch<IState, void, Action>) => Promise<void> => {
	//@ts-ignore
	return async (dispatch: ThunkDispatch<IState, void, Action>, state) => {
		dispatch(fundFetchingStatus('pending'))
		try {
			const response: any = await request('donate/getFundInfo', 'POST', {
				'fund_hash': state().app.fundHash
			})
			if (response.success) {
        // dispatch(setUserToken(response?.entity?.access_token || ''))
				dispatch({ type: SET_FUND_ENTITY, payload: {
					entity: response.entity,
					methods: response.methods,
				}})
				dispatch({ type: FETCH_PACKS, payload: response.methods })
				dispatch(fundFetchingStatus('success'))
				return
			}
			dispatch(fundFetchingStatus('failure'))
		} catch (e) {
			dispatch(fundFetchingStatus('failure'))
		}
	}
}

// Оплата
export const gamePayment = (data: any) => {
	return async (dispatch: ThunkDispatch<IState, void, Action>) => {
		return await request('donate/newGamePay', 'POST', data)
	}
}

// Ключ
export const activateDonate = (data: any, successKey:boolean = false) => {
  return async (dispatch: ThunkDispatch<IState, void, Action>) => {
    return await request('donate/activateDonate', 'POST', data)
  }
}

export const activateFundDonate = (data: any) => {
  return async (dispatch: ThunkDispatch<IState, void, Action>) => {
    return await request('donate/activateFundDonate', 'POST', data)
  }
}
