import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ExternalLink from 'components/Router/ExternalLink/ExternalLink'
import { toggleFeedback } from 'store/app/actions'
import {getPolicyUrl, getPublicOfferUrl} from 'store/app/selectors'
import { home } from 'router/routes'
import { discord, vk, youtube } from 'router/links'

import { useTranslation } from 'react-i18next';

const Footer: FC = () => {
	const dispatch = useDispatch()
	const publicOfferUrl = useSelector(getPublicOfferUrl)
	const policyUrl = useSelector(getPolicyUrl)

	const handleFeedback = () => {
		dispatch(toggleFeedback(true))
	}

	const { t } = useTranslation();

	return (
		<footer className="footer-main">
			<div className="footer-main__container">
				<div className="footer-main__row">
					<div className="footer-main__col">
						<NavLink to={home}>
							<div className="icon footer-logo"/>
						</NavLink>
					</div>
					<div className="footer-main__col">
						<nav className="footer-social">
							<ul className="footer-social__items">
								<li className="footer-social__item">
									<ExternalLink href={discord} className="footer-social__link">
										<i className="icon icon__footer-discord"/>
									</ExternalLink>
								</li>
								<li className="footer-social__item">
									<ExternalLink href={vk} className="footer-social__link">
										<i className="icon icon__footer-vk"/>
									</ExternalLink>
								</li>
								<li className="footer-social__item">
									<ExternalLink href={youtube} className="footer-social__link">
										<i className="icon icon__footer-youtube"/>
									</ExternalLink>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				<div className="footer-main__row">
					<div className="footer-main__col">
						<nav className="footer-nav">
							<ul className="footer-nav__items">
								<li className="footer-nav__item">
									<div onClick={handleFeedback} className="footer-nav__link">
										{t('feedback')}
									</div>
								</li>
								{publicOfferUrl !== null && (
									<li className="footer-nav__item">
										<a
											href={publicOfferUrl}
											className="footer-nav__link"
											target="_blank"
										>
											{t('user_agreement')}
										</a>
									</li>
								)}
								{policyUrl !== null && (
									<li className="footer-nav__item">
										<a href={policyUrl} className="footer-nav__link" target="_blank">
											{t('personal_data_policy')}
										</a>
									</li>
								)}
							</ul>
						</nav>
					</div>

					{/*<div className="footer-main__col">*/}
					{/*	<nav className="footer-nav">*/}
					{/*		<ul className="footer-nav__items">*/}

					{/*		</ul>*/}
					{/*	</nav>*/}
					{/*</div>*/}

				</div>
				<div className="footer-main__row">
					<div className="footer-main__col footer-nav__link"
							 style={{'cursor': 'default', textAlign: 'center', marginTop: '1em'}}>
						{/*2023 © BLACK FIRE ASIA LIMITED. All rights reserved.*/}
						Black Fire Asia Limited CR No.3279155 Business Registration No.7531705
						<br />
						<span style={{ display: 'block', marginTop: '1rem', fontSize: '0.75rem' }}>
							ROOM 1207A, 12/F., 794-802 NATHAN ROAD, KLN, HONG KONG
						</span>
					</div>
				</div>
				<div className="footer-main__row" style={{ display: 'flex', flexFlow: 'row', gap: '1rem', alignItems: 'center', justifyContent: 'center', marginTop: '0.2rem'}}>
					<img
						className="footer-main__payment-icon"
						src="/assets/images/icons/visa.svg"
						style={{width: '4rem'}}
					/>

					<img
						className="footer-main__payment-icon"
						src="/assets/images/icons/mir.svg"
						style={{width: '4rem'}}
					/>

					<img
						className="footer-main__payment-icon"
						src="/assets/images/icons/sbp.svg"
						style={{width: '4rem'}}
					/>

					<img
						className="footer-main__payment-icon"
						src="/assets/images/icons/mastercard.svg"
						style={{width: '8rem'}}
					/>
				</div>
			</div>
		</footer>
	)
}

export default Footer