import React, { FC, HTMLAttributes, ReactNode } from 'react'
import ServerRoot from './ServerRoot/ServerRoot'
import ServerHeader from './ServerHeader/ServerHeader'
import ServerContainer from './ServerContainer/ServerContainer'
import ServerFooter from './ServerFooter/ServerFooter'
import './server.scss'

interface IServerProps extends HTMLAttributes<HTMLElement> {
	serverId: number,
	serverName: string,
	background: string,
	toHeader?: ReactNode,
	toContainer?: ReactNode,
	toFooter?: ReactNode,
}

const Server: FC<IServerProps> = (
	{ serverId,
		serverName,
		background,
		toHeader,
		toContainer,
		toFooter,
		...restProps
	}) => {

	return (
		<ServerRoot { ...restProps }>
			<ServerHeader id={serverId} name={serverName}>
				{ toHeader }
			</ServerHeader>
			<ServerContainer bgUrl={background}>
				{ toContainer }
			</ServerContainer>
			<ServerFooter>
				{ toFooter }
			</ServerFooter>
		</ServerRoot>
	)
}

export {
	Server
}
