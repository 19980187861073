import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const useFetch = (callback: Function, entity: any) => {
	const dispatch = useDispatch()

	useEffect(() => {
		if (entity['length'] === 0) {
			dispatch(callback())
		}
	}, [entity, callback, dispatch])
}
