import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { Pending as PendingState } from 'components/Entities/Gateway/Pending/Pending'
import { getFundFetchingStatus } from 'store/app/selectors'

import { useTranslation } from 'react-i18next';

const Pending: FC = () => {
	const fundStatus = useSelector(getFundFetchingStatus)

	const pendingStatus = useMemo(() => {
		if (fundStatus === 'pending') {
			return 'fund'
		}
		return 'success'
	}, [fundStatus])

	const { t } = useTranslation();

	return (
		<PendingState>
			{ pendingStatus === 'fund' && t('pending_fund_state') }
			{ pendingStatus === 'success' && t('pending_success_state') }
		</PendingState>
	)
}

export default Pending
