import React, { FC, Fragment, useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getServerList } from 'store/app/selectors'
import { useFetch } from 'hooks/useFetch'
import { fetchServers } from 'store/app/actions'
import IServer from 'interfaces/IServer'
import { donate } from 'router/routes'
import Controller from 'components/Entities/Gateway/Controller/Controller'
import { useRequestChain } from 'hooks/useRequestChain'
import {userDonateScenario} from 'store/app/scenarios'

import Title from 'components/Template/Title/Title'
import Pending from 'components/Pages/Donate/User/Pending'
import Fail from 'components/Pages/Donate/Fail/Fail'
import User from 'components/Pages/Donate/User/User'

import { useTranslation } from 'react-i18next';

interface IServerProps {
	server: string,
}

const Server: FC = () => {
	const servers = useSelector(getServerList)
	const { server } = useParams<IServerProps>()
	const history = useHistory()
	useFetch(fetchServers, servers)
	const chainStatus = useRequestChain(userDonateScenario())
	const [ activeServer, setActiveServer ] = useState({
		name: ''
	})

	useEffect(() => {
		if (servers.length) {
			const foundServer = servers.find((serverItem: IServer) => {
				if (Number(server)) {
					return Number(serverItem.id) === Number(server)
				} else {
					const serverName = serverItem.name.toLowerCase()
					return serverName === server
				}
			})
			if (!foundServer) history.push(donate)
			else {
				setActiveServer(foundServer)
			}
		}
	}, [servers])

	const { t } = useTranslation();

	return (
		<Fragment>
			<Title bgTitle={activeServer && activeServer.name.toUpperCase()} className="donate__title">
				{t('adding_funds_on_server')} <span className="ttu">{activeServer && activeServer.name}</span>
			</Title>
			<Controller
				onWaiting={<Pending />}
				onError={<Fail />}
				// @ts-ignore
				onSuccess={<User server={activeServer} />}
				dependencyKey={chainStatus}
			/>
		</Fragment>
	)
}

export default Server
