import React, { FC } from 'react'

import { IDonatePacks } from 'interfaces/IDonatePacks'
import {useSelector} from "react-redux";
import {getCurrency} from "../../store/app/selectors";

import { useTranslation } from 'react-i18next';

interface IPacksProps {
	packs: IDonatePacks,
	handleClick: Function
}

const Packs: FC<IPacksProps> = ({ packs, handleClick }) => {
	const currency = useSelector(getCurrency)
	const { t } = useTranslation();

	return (
		<div className="packs">
			<div className="packs__col packs__col_invisible packs__col_thead">
				<div className="packs__row packs__row_large">
				</div>
				<div className="packs__row pack__typed pack__start">
					{t('pack_start')}
				</div>
				<div className="packs__row pack__typed pack__pro">
					{t('pack_pro')}
				</div>
				<div className="packs__row pack__typed pack__authority">
					{t('pack_authority')}
				</div>
				<div className="packs__row pack__typed pack__boss">
					{t('pack_boss')}
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					{t('game_currency')}
				</div>
				<div className="packs__row">
					20 000$
				</div>
				<div className="packs__row">
					35 000$
				</div>
				<div className="packs__row">
					75 000$
				</div>
				<div className="packs__row">
					250 000$
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					{t('pack_accessories')}
				</div>
				<div className="packs__row">
					{/*<i className="icon__donate" />*/}
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					{t('military_ticket')}
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col packs__col_disabled">
				<div className="packs__row packs__row_large">
					{t('pack_vehicle_license')}
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					{t('pack_car')}
				</div>
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					{t('pack_motorcycle')}
				</div>
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					{t('pack_cargo_vehicles')}
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					{t('pack_aircrafts')}
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					{t('pack_water_vehicles')}
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col packs__col_disabled">
				<div className="packs__row packs__row_large">
					{t('pack_license')}
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row" />
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					{t('pack_weapons')}
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					{t('pack_business')}
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col packs__col_margin packs__col_invisible">
				<div className="packs__row packs__row_large" />
				<div className="packs__row">
					<button
						type="button"
						className="packs__action packs__action_blue"
						onClick={() => handleClick(packs[0])}
					>
						{ packs[0].price } {currency}
					</button>
					<p className="packs__bonus">
						{t('pack_bonus')} +40 DP {t('pack_to_balance')}
					</p>
				</div>
				<div className="packs__row">
					<button
						type="button"
						className="packs__action packs__action_yellow"
						onClick={() => handleClick(packs[1])}
					>
						{ packs[1].price } {currency}
					</button>
					<p className="packs__bonus">
						{t('pack_bonus')} +50 DP {t('pack_to_balance')}
					</p>
				</div>
				<div className="packs__row">
					<button
						type="button"
						className="packs__action packs__action_purple"
						onClick={() => handleClick(packs[2])}
					>
						{ packs[2].price } {currency}
					</button>
					<p className="packs__bonus">
						{t('pack_bonus')} +150 DP {t('pack_to_balance')}
					</p>
				</div>
				<div className="packs__row">
					<button
						type="button"
						className="packs__action packs__action_orange"
						onClick={() => handleClick(packs[3])}
					>
						{ packs[3].price } {currency}
					</button>
					<p className="packs__bonus">
						{t('pack_bonus')} +999 DP {t('pack_to_balance')}
					</p>
				</div>
			</div>
		</div>
	)
}

export default Packs
