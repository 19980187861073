import React, { FC, Fragment } from 'react'
import Nav from './Nav/Nav'
import Footer from './Footer/Footer'
import Lines from './Lines/Lines'
import SystemPreloader from './SystemPreloader/SystemPreloader'
import ScrollToTop from './ScrollToTop/ScrollToTop'

const Template: FC = ({ children }) => {
	return (
		<Fragment>
			<Nav />
			<div className="container">
				{ children }
			</div>
			<Footer />
			<Lines />
			<SystemPreloader />
			<ScrollToTop />
		</Fragment>
	)
}

export default Template
