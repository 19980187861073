import React, { FC, useMemo, memo } from 'react'
import './serverContainer.scss'

interface IServerContainer {
	bgUrl: string,
	children: any,
}

const ServerContainer: FC<IServerContainer> = ({ bgUrl, children }) => {
	const serverStyles = useMemo(() => {
		return {
			backgroundImage: `url(${bgUrl})`
		}
	}, [bgUrl])

	return (
		<div
			className="server-container"
			style={serverStyles}
		>
			{ children }
		</div>
	)
}

export default memo(ServerContainer)
