import React, { FC } from 'react'
import Preloader from 'components/Entities/Preloader/Preloader'
import './pending.scss'

const Pending: FC = ({ children }) => {
	return (
		<div className="state-pending">
			<div className="state-pending__preloader">
				<Preloader />
			</div>
			<div className="state-pending__container">
				{ children }
			</div>
		</div>
	)
}

export {
	Pending
}
