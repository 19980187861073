import React, { FC, InputHTMLAttributes } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string,
	validate?: any,
}

const Input: FC<InputProps> = ({ label, id, validate, ...restProps }) => {
	return (
		<div className="input-wrapper">
			{ label &&
				<label className="input-label" htmlFor={id}>
					{ label }
				</label>
			}
			<input
				className="input-control"
				id={id}
				ref={validate}
				{ ...restProps }
			/>
		</div>
	)
}

export default Input
