import React, { FC, useMemo, InputHTMLAttributes } from 'react'

interface CheckboxTypes extends InputHTMLAttributes<HTMLInputElement>{
	name: string,
	id?: string,
	validate?: any,
}

const Checkbox: FC<CheckboxTypes> = (
	{ name,
		id,
		className= false,
		validate,
		...restProps
	}) => {
	const checkboxId = useMemo(() => {
		return id ? id : name
	}, [name, id])

	const classNames = className ? className : ''

	return (
		<label
			htmlFor={checkboxId}
			className={`checkbox ${classNames}`}
		>
			<input
				type="checkbox"
				name={name}
				id={checkboxId}
				ref={validate}
				className="checkbox__control"
				{ ...restProps }
			/>
		</label>
	)
}

export default Checkbox
