import {useMemo} from 'react';

export const useBonus = () => {

	const calculateBonus = (sum: string | number) => {

    const start = new Date(1726174800000)
    const now = new Date()
    const end = new Date(1726433999000)

    if (now > start && now < end) {
			return Number(sum) * 2
    }

		const numberSum = Number(sum)
		if (numberSum < 0) {
			return 0
		}
		let localSum = numberSum
		if (numberSum >= 100000) {
			const bonus = numberSum / 100 * 50
			localSum += bonus
			return localSum
		}
		if (numberSum >= 15000) {
			const bonus = numberSum / 100 * 25
			localSum += bonus
			return localSum
		}
		if (numberSum >= 5000) {
			const bonus = numberSum / 100 * 15
			localSum += bonus
			return localSum
		}
		return localSum
	}

	return [ calculateBonus ]
}
