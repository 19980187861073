import initialState from "./state"
import {
	SET_USER_DATA,
	SET_USER_STATUS,
	SET_USER_TOKEN,
	SET_DONATE_PRESETS,
	RESET_DONATE_PRESETS
} from './types'
import { UserActionTypes } from './types/actions'

export const userReducer = (state = initialState, { type, payload }: UserActionTypes) => {
	switch (type) {
		case SET_USER_DATA: {
			return {
				...state,
				data: payload
			}
		}
		case SET_USER_TOKEN: {
			return {
				...state,
				token: payload
			}
		}
		case SET_USER_STATUS: {
			return {
				...state,
				auth: payload
			}
		}
		case SET_DONATE_PRESETS: {
			return {
				...state,
				external: {
					sum: payload
				}
			}
		}
		case RESET_DONATE_PRESETS: {
			return {
				...state,
				external: null
			}
		}
		default: return state
	}
}
