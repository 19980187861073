import React, { FC, Fragment } from 'react'
import { useSelector } from 'react-redux'

import { getUserStatus } from 'store/user/selectors'
import { IService } from 'interfaces/IService'
import './service.scss'

const Service: FC<IService> = (
	{ name,
		cost,
		auth
	}) => {
	const isAuthenticated = useSelector(getUserStatus)

	return (
		<Fragment>
			{ isAuthenticated === true &&
      <div className="donate-service">
        <p className="donate-service__name">
					{ name }
        </p>
        <p className="donate-service__cost">
					{ cost }
        </p>
      </div>
			}
			{ isAuthenticated === false &&
				<Fragment>
					{ auth === isAuthenticated &&
          <div className="donate-service">
            <p className="donate-service__name">
							{ name }
            </p>
            <p className="donate-service__cost">
							{ cost }
            </p>
          </div>
					}
				</Fragment>
			}
		</Fragment>
	)
}

export default Service
