import { useEffect, useState } from 'react'

export const useErrorMessage = (formState: any, errors: any = {}) => {
	const [ errorMessage, setErrorMessage ] = useState('')

	useEffect(() => {
		if (Object.keys(errors).length) {
			const firstKey = Object.keys(errors)[0]
			// @ts-ignore
			setErrorMessage(errors[firstKey].message)
		} else {
			setErrorMessage('')
		}
	}, [formState, errors])

	return [ errorMessage, setErrorMessage ]
}
