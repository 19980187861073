import React, { FC, Fragment } from 'react'
import Title from 'components/Template/Title/Title'
import Controller from 'components/Entities/Gateway/Controller/Controller'
import Pending from 'components/Pages/Donate/Pending/Pending'
import Fail from 'components/Pages/Donate/Fail/Fail'
import { Guest as DonateGuest } from 'components/Pages/Donate/Guest/Guest'
import { useRequestChain } from 'hooks/useRequestChain'
import { donateScenario } from 'store/app/scenarios'

import { useTranslation } from 'react-i18next';

const Guest: FC = () => {
	const chainStatus = useRequestChain(donateScenario())

	const { t } = useTranslation();

	return (
		<Fragment>
			<Title bgTitle={t('donate_replenishment')} className="donate__title">
				{t('adding_funds')}
			</Title>
			<Controller
				onWaiting={<Pending />}
				onError={<Fail />}
				onSuccess={<DonateGuest />}
				dependencyKey={chainStatus}
			/>
		</Fragment>
	)
}

export default Guest
