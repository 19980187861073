import {
	SET_CAPTCHA_KEY,
	SET_CAPTCHA_TOKEN,
	SET_CAPTCHA_ACTION
} from './types'
import {
	ISetCaptchaKey,
	ISetCaptchaToken,
	ISetCaptchaAction
} from './types/actions'

export const setCaptchaKey = (key: string | null): ISetCaptchaKey => {
	return {
		type: SET_CAPTCHA_KEY,
		payload: key
	}
}

export const setCaptchaToken = (key: string | null): ISetCaptchaToken => {
	return {
		type: SET_CAPTCHA_TOKEN,
		payload: key
	}
}

export const setCaptchaAction = (callback: Function): ISetCaptchaAction => {
	return {
		type: SET_CAPTCHA_ACTION,
		payload: callback
	}
}
