import {
	FETCH_PACKS,
	FETCH_PAYMENTS,
	FETCH_ROLES,
	FETCH_SERVERS,
	FUND_FETCHING_STATUS,
	PACKS_FETCHING_STATUS,
	PAYMENTS_FETCHING_STATUS,
	ROLES_FETCHING_STATUS,
	SET_FUND,
	SET_FUND_ENTITY,
	SET_META,
	TOGGLE_FEEDBACK,
	TOGGLE_REQUEST_LOADER,
	TOGGLE_SERVERS_LOADER,
	META_FETCHING_STATUS,
} from './types'
import {AppActionTypes} from './types/actions'
import initialState from './state'

export const appReducer = (state = initialState, { type, payload }: AppActionTypes) => {
	switch(type) {
		case TOGGLE_REQUEST_LOADER:
			return {
				...state,
				requestLoader: payload
			}
		case TOGGLE_SERVERS_LOADER:
			return {
				...state,
				serversLoading: payload
			}
		case FETCH_SERVERS:
			return {
				...state,
				servers: payload,
			}
		case FETCH_PAYMENTS:
			return {
				...state,
				payments: payload,
			}
		case PAYMENTS_FETCHING_STATUS:
			return {
				...state,
				paymentsFetchingStatus: payload,
			}
		case FETCH_PACKS:
			return {
				...state,
				packs: payload,
			}
		case PACKS_FETCHING_STATUS:
			return {
				...state,
				packsFetchingStatus: payload,
			}
		case FETCH_ROLES:
			return {
				...state,
				roles: payload,
			}
		case ROLES_FETCHING_STATUS:
			return {
				...state,
				rolesFetchingStatus: payload,
			}
		case FUND_FETCHING_STATUS:
			return {
				...state,
				fundFetchingStatus: payload,
			}
		case TOGGLE_FEEDBACK: {
			return {
				...state,
				feedback: payload
			}
		}
		case SET_FUND: {
			return {
				...state,
				fundHash: payload
			}
		}
		case SET_FUND_ENTITY: {
			return {
				...state,
				fund: payload
			}
		}
		case META_FETCHING_STATUS:
			return {
				...state,
				metaFetchingStatus: payload,
			}
		//@ts-ignore
		case SET_META: {
			console.log(payload)
			return {
				...state,
				...payload,
			}
		}
		// Прелоадер загрузки серверов
		default: return state
	}
}
