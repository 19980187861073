import React, {
  FC,
  useRef,
  useCallback,
  useState,
  Fragment,
} from 'react'
import type { ChangeEvent } from 'react'

import CardItem from './CardItem/CardItem'
import Transition  from '../../Transition/Transition'
import Button from '../../Entities/Form/Button/Button'
import Input from '../../Entities/Form/Input/Input'
import { activateDonate, activateFundDonate } from '../../../store/app/actions'
import './card.scss'
import {useDispatch, useSelector} from 'react-redux'
import {getUserStatus} from '../../../store/user/selectors'
import {getCurrency, getFund, getFundHash} from '../../../store/app/selectors'

import { useTranslation } from 'react-i18next';

interface ICardProps {
	paymentType?: string,
	login: string,
	server?: string | null,
	donateType?: string,
	role?: string | null,
	donateSum: number | null,
	sum: number | null,
	handleBack: Function | null
  isPromo?: boolean
  setIsPromo: (promo: boolean) => void
}

const Card: FC<ICardProps> = (
	{ paymentType = 'payment_type',
		login,
		server = null,
		donateType = 'donate_type',
		role = null,
		donateSum,
		sum,
		handleBack,
    isPromo,
    setIsPromo,
	}) => {
  const dispatch = useDispatch()

  const fundHash = useSelector(getFundHash)

  const inputValue = useRef('')

const currency = useSelector(getCurrency)

  const handleInput = useCallback((
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target

    inputValue.current = value
  }, [])

  const [
    error,
    setError
  ] = useState('')

  const [
    success,
    setSuccess
  ] = useState('')

  const handleSubmit = async () => {
    const response = await dispatch(
fundHash ? activateFundDonate({
        key: inputValue.current,
        fund_hash: fundHash
      })
      : activateDonate({ key: inputValue.current })
    )

    if (!response) return

    //@ts-ignore
    if (!response?.success) {
      //@ts-ignore
      setError(response.error_text)
      return
    }

    setError('')
    //@ts-ignore
    setSuccess(response?.text || '')
    return
  }

  //@ts-ignore
  const handleClick = (e) => {
    e.preventDefault()

    if (isPromo) {
      setIsPromo(false)
      return
    }

    if (!handleBack) return
    // @ts-ignore
    handleBack()
  }

    const { t } = useTranslation();

  return (
    <div className="donate-card">
      { handleBack &&
        <div className="donate-card__back" onClick={handleClick}>
          <i className="icon icon__arrow-left" />
        </div>
      }
      <div className="donate-card__root">
        <div className="donate-card__role" />
        <p className="donate-card__name">
          { login }
        </p>
        <p className="donate-card__about">
          { isPromo ? t('key_activation') : t(paymentType) }
        </p>
        <Transition dependencyKey={isPromo}>
          { !isPromo && (
            <div>
              <div className="donate-card__container">
                { server && <CardItem name={t('server_title')} value={server} /> }
                <CardItem name={t('product_type')} value={donateType} />
                { role && <CardItem name={t('character_title')} value={role} /> }
                <CardItem name={t('amount_title')} value={`${donateSum} DP`} />
              </div>
              <div className="donate-card__sum">
                <p>
                    {t('payment_amount')} <strong>{ sum } {currency}</strong>
                </p>
              </div>
            </div>
          ) }
          { isPromo && (
            <div>
              <div
                className="donate-card__container"
                style={{marginTop: '20px'}}
              >
                <a
                  target="_blank"
                  href="https://www.kinguin.net/c/124940/5rp"
                  className="getKey"
                >
                  <Button>
                      {t('buy_key')}
                  </Button>
                </a>
                <Transition dependencyKey={error}>
                  { !error && (
                    <label
                      htmlFor="donateInput"
                      className="donate-card__label"
                    >
                      { success ? success : t('enter_key') }
                    </label>
                  ) }

                  { error && (
                    <label
                      htmlFor="donateInput"
                      className={'donate-card__label' + ' donate-card__label_errored'}
                    >
                      { error }
                    </label>
                  ) }
                </Transition>
                <input
                  id="donateInput"
                  className="donate-card__input"
                  onChange={handleInput}
                />
                {/*{ server && <CardItem name="Сервер" value={server} /> }*/}
                {/*<CardItem name="Тип товара" value={donateType} />*/}
                {/*{ role && <CardItem name="Персонаж" value={role} /> }*/}
                {/*<CardItem name="Количество" value={`${donateSum} DP`} />*/}
              </div>
              <button
                type="button"
                className={'donate-card__sum' + ' donate-card__sum_code'}
                onClick={handleSubmit}
              >
                <p>
                    {t('activate')}
                </p>
              </button>
            </div>
          ) }
        </Transition>
      </div>
    </div>
  )
}

export default Card
