import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getRolesList, getServerList } from 'store/app/selectors'
import IServer from 'interfaces/IServer'
import Server from 'components/Pages/Servers/Server/Server'
import './success.scss'

const Success: FC = () => {
	const servers = useSelector(getServerList)
	const roles = useSelector(getRolesList)

	return (
		<div className="servers" style={{ marginBottom: '64px' }}>
			{ servers.map((server: IServer, id: number) =>
				<div className="servers__item" key={server.id}>
					<Server
						id={server.id}
						name={server.name}
						background={server.background}
						roles={roles}
					/>
				</div>
			) }
		</div>
	)
}

export default Success
