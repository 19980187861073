import React, { FC, ReactNode } from 'react'
import { RequestStatusType } from 'types/RequestStatusType'
import { RequestChainTypes } from 'types/RequestChainTypes'
import Transition from 'components/Transition/Transition'

interface ContainerProps {
	onWaiting: ReactNode,
	onError: ReactNode,
	onSuccess: ReactNode,
	dependencyKey: RequestStatusType | RequestChainTypes | any,
}

const Controller: FC<ContainerProps> = ({ onWaiting, onError, onSuccess, dependencyKey }) => {
	return (
		<Transition dependencyKey={dependencyKey}>
			{ dependencyKey === 'pending' && onWaiting }
			{ dependencyKey === 'failure' && onError }
			{ dependencyKey === 'success' && onSuccess }
		</Transition>
	)
}

export default Controller
