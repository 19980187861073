export const useEnumerate = (number: number, variants: [string, string, string]) => {
	if (number > 100) {
		number = number % 100
	}
	if (number <= 20 && number >= 10) {
		return `${number} ${variants[2]}`
	}
	if (number > 20) {
		number = number % 10
	}

	const variant = number === 1
		? variants[0]
		: number > 1 && number < 5
			? variants[1]
			: variants[2]

	return `${number} ${variant}`
}
