import { useDispatch, useSelector } from 'react-redux'
import { useEffect} from "react";
import {fetchMeta} from "../store/app/actions";
import { useLocation} from "react-router-dom";
// import {loginUser} from "../store/user/actions";

export const useMeta = () => {
    const dispatch = useDispatch()

    const location = useLocation()

    const query = new URLSearchParams(location.search).get('from')

    const fromGame = query === null ? null : query

    useEffect(() => {
        dispatch(fetchMeta(fromGame))
    }, [dispatch])
}